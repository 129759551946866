import { json } from 'react-router'
import Images from '../../../utility/imagesPath'
import './howItsWorksStepsComponent.css'

const STEPS_DATA = [
    {
        title: 'Opinion',
        description: 'Tired of thinking the same way?Grooks brings new opinions on the topics you love',
        icon: Images.SEELCT_TOUCH_ICON,
        stepCount: 1,
        cardBackgroundColor: 'rgba(255,230,120,0.2)'
    },
    // {
    //     isBlankComponent: true
    // },
    // {
    //     isBlankComponent: true
    // },
    {
        title: 'Earn',
        description: 'Get rewards from sharing your opinion and earn on a guilt-free opinion market',
        icon: Images.EARN_ICON,
        stepCount: 2,
        cardBackgroundColor: 'rgba(123,251,227,0.2)'
    },
    {
        title: 'Party',
        description: `Share, vote, and be rewarded; there is no cap on your rewards. It's open to all.`,
        icon: Images.PARTY_BOTTOLE_ICON,
        stepCount: 3,
        cardBackgroundColor: 'rgba(165,247,174,0.2)'
    },
]

const RenderStep = ({ data }) => {
    const IS_RIGHT_COMPONENT = data.stepCount == 2
    return (
        <div className='renderStepContainer' >
            <div className='cardContainer' style={{ backgroundColor: data.cardBackgroundColor }} >
                <img
                    src={data.icon}
                    className='stepIcon'
                />
                <p className='stepTitle title' >{data.title}</p>
                <p className='stepDescription' >{data.description}</p>
                <p className='stepCountInsideCard' >0{data.stepCount}</p>
            </div>
            <div className='dashContainer' >
                <div className='dashContainer_horizontalArrowContainer'  >
                    <div className='dashContainer_horizontalArrowContainer_dash' />
                    <p>{IS_RIGHT_COMPONENT ? '<' : '>'}</p>
                </div>

                <div className='dashContainer_verticalArrowContainer' >
                    {/* <p>^</p> */}
                    <div className='dashContainer_verticalArrowContainer_dash' />
                    <div className='dashContainer_stepCountContainer' >
                        <p >{data.stepCount}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const HowItsWorksStepsComponent = () => {
    return (
        <div className='howItWorksStep_mainContainer' >
            {
                STEPS_DATA.map((item, index) => {
                    return (
                        <RenderStep key={JSON.stringify(item) + index} data={item} />
                    )
                })
            }
            {/* <RenderStep data={STEPS_DATA[0]} />
            <div>
                <RenderStep data={STEPS_DATA[1]} />
            </div>
            <RenderStep data={STEPS_DATA[2]} /> */}

        </div>
    )
}

export default HowItsWorksStepsComponent;