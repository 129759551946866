import React from "react";
import Images from "../../../../utility/imagesPath";
import './appDownloadButton.css'
import { useGetAppSettingQuery } from "../../../../redux/api/setting/appSetting.api";

const AppDownloadButton = () => {
    const { data: setting } = useGetAppSettingQuery()

    function onPressDownloadNow() {
        window.open(setting?.data?.apkLink, '_blank', 'noopener,noreferrer');
    }

    return (
        <button onClick={onPressDownloadNow} className="downloadButtonContainer" >
            {/* <img src={Images.DOWNLOAD_ICON} /> */}
            Download Now
        </button>
    )
}

export default AppDownloadButton;