import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TAG_OPINION } from '../../apiTages';
import { API_SURVEY_APP_ENDPOINT_OPINION, SURVEY_APP_BASE_URL } from '../../apiTypes';
import { header } from '../../header';

export const OPINION_API = createApi({
    reducerPath: "OPINION_API",
    baseQuery: fetchBaseQuery({
        baseUrl: SURVEY_APP_BASE_URL,
        // headers: headers,
        prepareHeaders: (headers) => { return header(headers) },
        cache: 'no-cache',
    }),
    tagTypes: [TAG_OPINION],
    endpoints: (builder) => ({
        submitOpinion: builder.mutation({
            query: (payload) => ({
                url: API_SURVEY_APP_ENDPOINT_OPINION,
                method: 'POST',
                body: payload,
            }),
            providesTags: [TAG_OPINION],
        }),
    })
})
export const {
 useSubmitOpinionMutation
} = OPINION_API;

