import './customButton.css';

const CustomButton = ({ marginTop, title,onClick }) => {
    return (
        <button className='buttonMainContainer' onClick={onClick} style={{ marginTop: marginTop ? marginTop : 0 }} >
            {title}
        </button>
    )
}

export default CustomButton;