
export async function header(headers) {
    headers.set("Accept", 'application/json',);
    headers.set('Platform', 'WEB',);

    // const token = Cookies.get(KEY_USER_TOKEN);

    // if (token) {
    //     headers.set("Authorization", `Bearer ${token}`);
    // }

    return headers;
}