import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TAG_APP } from '../../apiTages';
import { API_ENDPOINT_SETTING, BASE_URL } from '../../apiTypes';

export const APP_SETTING_API = createApi({
    reducerPath: "APP_SETTING_API",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        // prepareHeaders: (headers) => { return header(headers) }
    }),
    tagTypes: [TAG_APP],
    endpoints: (builder) => ({
        getAppSetting: builder.query({
            query: () => API_ENDPOINT_SETTING,
            providesTags: [TAG_APP],
        }),
    })
})
export const { useGetAppSettingQuery } = APP_SETTING_API;

