export  const INPUT_TYPE = {
    BUTTON: "button",
    CHECKBOX: "checkbox",
    DATE: "date",
    EMAIL: "email",
    FILE: "file",
    IMAGE: "image",
    NUMBER: "number",
    PASSWORD: "password",
    RADIO: "radio",
    RANGE: "range",
    RESET: "reset",
    SEARCH: "search",
    SUBMIT: "submit",
    TEL: "tel",
    TEXT: "text",
    TIME: "time",
    URL: "url",
    WEEK: "week",
}

export const CURRENCY = '₹'

export const REWARDS_TYPE = {
    GROOKS_POINTS: 'GROOKS_POINTS',
    MONEY: 'MONEY',
  }