import React, {  } from 'react'
import  ReactDOM  from 'react-dom'
import './cutsomModal.css'

const CustomModal = ({ visible, onClose, children }) => {
    return (
        <>
            {
                visible && ReactDOM.createPortal(
                    <div className="modal-overlay" >
                        <div className="modal" onClick={() => { }} >
                            <div className="modal-content" onClick={() => { }}>
                                {children}
                            </div>
                        </div>
                    </div>, document.querySelector('.portalModal')
                )
            }
        </>
    )
}

export default CustomModal