import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TAG_POLLS } from '../../apiTages';
import { API_SURVEY_APP_ENDPOINT_POLL, SURVEY_APP_BASE_URL } from '../../apiTypes';
import { header } from '../../header';

export const POLL_API = createApi({
    reducerPath: "POLL_API",
    baseQuery: fetchBaseQuery({
        baseUrl: SURVEY_APP_BASE_URL,
        // headers: headers,
        prepareHeaders: (headers) => { return header(headers) },
        cache: 'no-cache',
    }),
    tagTypes: [TAG_POLLS],
    endpoints: (builder) => ({
        getPollDetails: builder.query({
            query: (id) => API_SURVEY_APP_ENDPOINT_POLL + id,
            providesTags: [TAG_POLLS],

        }),
    })
})
export const {
    useGetPollDetailsQuery,
    useLazyGetPollDetailsQuery
} = POLL_API;

