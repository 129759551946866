import { configureStore } from "@reduxjs/toolkit";
import { OPINION_API } from "./api/opinion/opinion.api";
import { POLL_API } from "./api/poll/poll.api";
import { APP_SETTING_API } from "./api/setting/appSetting.api";

const store = configureStore({
    reducer: {
        [POLL_API.reducerPath]: POLL_API.reducer,
        [OPINION_API.reducerPath]: OPINION_API.reducer,
        [APP_SETTING_API.reducerPath]: APP_SETTING_API.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, }).concat(
        POLL_API.middleware,
        OPINION_API.middleware,
        APP_SETTING_API.middleware,
    ),
})

export default store