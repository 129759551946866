import { useGetAppSettingQuery } from "../../../redux/api/setting/appSetting.api"
import Images from "../../../utility/imagesPath"
import Footer from "../../common/footer/footer"
import FeatureComponent from "../../module/featureComponent/featureComponent"
import HeaderComponent from "../../module/header"
import HowItsWorksStepsComponent from "../../module/howItsWorksStepsComponent/howItsWorksStepsComponent"
import ReviewCardComponent from "../../module/reviewCardComponent/reviewCardComponent"
import './index.css'

const Home = () => {
    return (
        <div>
            <div className="headerAndHeroContainer" >
                <HeaderComponent />
                <div className="heroContainer" >
                    <div className="heroContainer_textAndImgContainer" >
                        <div className="" >
                            <p>Welcome to grooks</p>
                            <h1 className="" >Lot's of opinion in mind?</h1>
                            <h2>Let's trade them now</h2>
                        </div>
                        {/* <div> */}
                            <img src={Images.LANDING_PAGE_MOBILE_IMG} />
                        {/* </div> */}
                    </div>
                    <p className="heroContainer_bottomText" >Trade-in and win money with your most accurate and sharpest opinions on global events and topics in a fun, collaborative, and rewarding way. Opinion is a commodity. Trade it for profit!</p>
                </div>
            </div>
            {/* ----------------------HOW IT WORKS---------------------- */}
            <div className="howItsWorksContainer appPaddingHorizontal" >
                <h6 className="heading" >How It's Work</h6>
                <p className="howItsWorksContainer_subHeading" >Grooks makes opinion fun! How? It's simple. Just match your opinion to a counter argument in an easy, um... a very easy way, and you are in!</p>
                <HowItsWorksStepsComponent />
            </div>
            {/*  */}
            <div className="grooksFeaturesContainer" >
                <div className="grooksFeaturesContainer_headingContainer" >
                    <h6 className="heading" >Grooks ensures your well-being, empowering you to nurture your trades in return</h6>
                    <img src={Images.GROOKS_FEATURES_SMALL_BANNER} />
                </div>
                <div className="grooksFeaturesContainer_listContainer" >
                    <div className="grooksFeaturesContainer_listContainer_fetaureGroup" >
                        <FeatureComponent
                            title={'Select the category you want to share'}
                            description={'Just click on the event to choose your category. Using the feed, you can select different categories or events'}
                            icon={Images.CATEGORY_CIRCULAR_ICON}
                        />
                        <FeatureComponent
                            title={'Post an opinion that can be answered YES or NO'}
                            description={`You can say yes or no to the outcome. In each box, you'll find the price you'll need to pay immediately to play`}
                            icon={Images.HEART_CIRCULAR_ICON}
                            isReverse
                        />
                    {/* </div> */}
                    {/* <div className="grooksFeaturesContainer_listContainer_fetaureGroup" style={{ width: '80%' }} > */}
                        <FeatureComponent
                            title={'Get paid for sharing your opinion!'}
                            description={`If you guess 'Yes,' we will find a user who guesses 'No'`}
                            icon={Images.GET_PAID_CIRCULAR_ICON}
                        />
                        <FeatureComponent
                            title={'Easy access to rewards'}
                            description={`Besides helping you learn important financial & trading skills, Grooks alsohelps you understand the collective thoughts of Indians. Knowledge that is crucial for the betterment of our country.`}
                            icon={Images.EIGHTEEN_PLUS_CIRCULAR_ICON}
                            isReverse
                        />
                    </div>
                </div>
                <div className="grooksFeaturesContainer_backgroundClip" />
            </div>

            {/* ------------------------------------PAYMENT SECTION----------------------------------- */}
            <div className="paymentContainer" >
                <div>
                    <h6 className="heading" >Get payments directly to your bank account.</h6>
                    <p className="paymentContainer_description" >Receive payments directly into your bank account with ease and speed. This streamlined process eliminates intermediaries, ensuring your funds are securely deposited.</p>
                </div>
                <img src={Images.PAYMENT_BANNER} />
            </div>

            {/* REVIEWS SECTION */}
            {/* <div className="reviewsSectionContainer" >
                <h6 className="heading">Make your Opinion worth Millions with Grooks</h6>
                <div className="reviewsSectionContainer_reviewsContainer" >
                    <ReviewCardComponent />
                    <ReviewCardComponent />
                    <ReviewCardComponent />
                    <ReviewCardComponent />
                    <ReviewCardComponent />
                    <ReviewCardComponent />
                </div>
                <img src={Images.WORLD_BACKGROUND_IMG} />
            </div> */}

            {/* ------------------------------------OUR TEAM----------------------------------- */}
            <Footer />

        </div>
    )
}

export default Home