import React from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Toaster = (props) => {
    const option = {
        position: "bottom-right",
        autoClose: 5000,
        // hideProgress: false,
        // closeOnClick: true,
        // pauseOnHover: true,
        // graggable: true,
        // progress: undefined,
        theme: "light",
        type: props.type !== undefined ? props.type : "",
    };
    toast(props.message, option);
};

export default Toaster;