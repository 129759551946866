import { useGetAppSettingQuery } from "../../../redux/api/setting/appSetting.api";
import { GROOKS_TERMS_AND_CONDITION } from "../../../redux/apiTypes";
import Images from "../../../utility/imagesPath";
import './footer.css';

const Footer = () => {

    const { data: setiingRes } = useGetAppSettingQuery()

    function onPressDownloadNow() {
        window.location.href = setiingRes?.data?.apkLink;
    }
    function onPressLink() {
        window.open(GROOKS_TERMS_AND_CONDITION, '_blank', 'noopener,noreferrer');
    }

    return (
        <div className="footerMainContainer" >
            <div className="footerMainContainer_topContainer" >
                <div className="footerMainContainer_topContainer_grooksLogoAndSocialMediaContainer" style={{ flex: 2 }} >
                    <img src={Images.APP_LOGO} />
                    <p  >Get rewards from sharing your opinion and earn on a guilt-free opinion market</p>
                    <div className="socialMedia_handleCotainer">
                        {/* icons */}
                        <img src={Images.FACEBOOK} />
                        <img src={Images.TWITTER} />
                        <img src={Images.LINKEDIN} />
                        <img src={Images.INSTAGRAM} />
                    </div>
                    <div className="policyBtn_container" >
                        <button onClick={onPressLink} >
                            Terms & Condition
                        </button>
                        <button onClick={onPressLink} >
                            Privacy Policy
                        </button>
                        <button onClick={onPressLink} >
                            Refund Policy
                        </button>
                    </div>
                </div>
                <div style={{ flex: 1 }} />
                {/* <div className="footerMainContainer_companyContainer" >
                    <h6 className="sectionTitle" >Company</h6>
                    <p>About Us</p>
                    <p>Contact</p>
                </div> */}
                {/* <div className="footerMainContainer_supportContainer" >
                    <h6 className="sectionTitle" >Support</h6>
                    <p>Community</p>
                    <p>Resources</p>
                    <p>Faqs</p>
                    <p>Privacy Policy</p>
                    <p>Careers</p>
                </div> */}
                <div className="footerMainContainer_getInTouchContainer" >
                    <h6 className="sectionTitle" >Get In Touch</h6>
                    <p>support@grooks.in</p>
                    <p>+91 9546216600</p>
                    {/* <p>Registered Office :- 410 , Officers Colony, DaniBigha, Aurangabad,Bihar,824101</p> */}
                    <p style={{ marginTop: '1rem' }} >Address : Dhi Grooks Media Tech Pvt Ltd.Block A, 5th floor, Maurya lok complex, Budh Vihar, Fraser Road Area, Patna, Bihar 800001</p>
                </div>


            </div>
            <div className="footerMainContainer_bottomContainer" >
                <button onClick={onPressDownloadNow} >
                    <img src={Images.DOWNLOAD_ICON} />
                    Download Now
                </button>
                <p>Copyright © 2024 Grooks (DHI Grooks MEDIA TECH PRIVATE LIMITED)</p>
            </div>
        </div>
    )
}

export default Footer;