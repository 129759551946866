import './eventOptionsComponent.css'

const EventOptionsComponent = ({ data , onSelectOption , selectedOption}) => {
    return (
        <div>
            {
                data.map((item, index) => {
                    return (
                        <div key={index} className='optionContainer' onClick={() => onSelectOption(item)} style={{marginTop : index === 0 ? 0 : '0.5rem', backgroundColor :  selectedOption?.id == item?.id? ' rgba(1, 71, 146, 1)' : 'white'  }} >
                            <p style={{color :  selectedOption?.id == item?.id? 'white' : 'rgba(1, 71, 146, 1)'  }} >{item?.option || ''}</p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default EventOptionsComponent