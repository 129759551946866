const Images = {
    APP_LOGO : require('../assets/images/app_logo.png'),
    DOWNLOAD_ICON : require('../assets/images/download_icon.png'),
    LANDING_PAGE_MOBILE_IMG : require('../assets/images/landing_page_mobile_img.png'),
    PLAY_ICON : require('../assets/images/play_icon.png'),
    SEELCT_TOUCH_ICON : require('../assets/images/select_touch_icon.png'),
    PARTY_BOTTOLE_ICON : require('../assets/images/party_bottle.png'),
    EARN_ICON : require('../assets/images/earn.png'),
    EIGHTEEN_PLUS_CIRCULAR_ICON : require('../assets/images/18+_circle_iocn.png'),
    GET_PAID_CIRCULAR_ICON : require('../assets/images/get_paid_icon.png'),
    CATEGORY_CIRCULAR_ICON : require('../assets/images/category_circle.png'),
    HEART_CIRCULAR_ICON : require('../assets/images/heart_circle_icon.png'),
    PAYMENT_BANNER : require('../assets/images/payment_banner.png'),
    WORLD_BACKGROUND_IMG : require('../assets/images/world_background_img.png'),
    FACEBOOK : require('../assets/images/facebook.png'),
    INSTAGRAM : require('../assets/images/instagram.png'),
    TWITTER : require('../assets/images/twitter.png'),
    LINKEDIN : require('../assets/images/linkedin.png'),
    TERMS_AND_CONDITION : require('../assets/images/T&C.png'),
    RESPONSIBLE_PLAY: require('../assets/images/responsible_play.png'),
    SMALL_DOWNLOAD_ICON: require('../assets/images/small_download_icon.png'),
    OPINION_SUCCESS: require('../assets/images/trade_success.png')
}

export default Images