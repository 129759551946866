import { useState } from 'react'
import './labelInput.css'

const LabelInput = ({ onChangeValue, title, value, error, type, multiple, mainContainerStyle, placeholder }) => {

    const [isFocused, setIsFocused] = useState(false)

    const onFocus = () => {
        setIsFocused(true)
    }

    return (
        <div className='' style={{ display: 'flex', flexDirection: 'column', ...mainContainerStyle }}   >
            <p className='label' >{title}</p>
            <input
                type={type}
                onChange={(e) => onChangeValue(e.target.value)}
                value={value}
                className='labelInput'
                placeholder={placeholder || ''}
                onFocus={onFocus}
                style={{ borderColor: isFocused ? ' rgba(1, 71, 146, 1)' : 'rgb(79, 79, 79)' }}
                multiple={multiple}
            />
            <p className='error' >{error}</p>
        </div>
    )
}

export default LabelInput;