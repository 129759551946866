import { useEffect, useState } from 'react';
import CustomButton from '../../common/buttons/CustomButton/customButton';
import CustomModal from '../../common/customModal/cutsomModal';
import LabelInput from '../../common/input/labelInput/labelInput';
import EventOptionsComponent from '../../module/eventOptionsComponent/eventOptionsComponent';
import './tradeOpinionModal.css';
import { CURRENCY, INPUT_TYPE, REWARDS_TYPE } from '../../../utility/constants';
import { isInputEmpty, validateMobileNumber } from '../../../utility/validations';
import { useSubmitOpinionMutation } from '../../../redux/api/opinion/opinion.api';
import Toaster from '../../common/toaster';
import { ToastContainer } from 'react-toastify';
import Images from '../../../utility/imagesPath';
// import { ImagesPath } from '../../../../utility/images';

const TradeOpinionModal = ({ visible, onClose, selectedOption, pollData, onSelectOption }) => {

    const [name, setName] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [note, setNote] = useState('')
    const [nameError, setNameError] = useState('')
    const [mobileNumberError, setMobileNumberError] = useState('')

    const [showParticiantsInput, setShowParticiantsInput] = useState(false)
    const [showTradeSuccessfulModal, setShowTradeSuccessfulModal] = useState(false)

    const [submitOpinion] = useSubmitOpinionMutation()

    useEffect(() => {
        setShowParticiantsInput(false)
    }, [visible])

    function onPressNext() {
        setShowParticiantsInput(true)
    }

    async function placeTrade() {
        try {
            const nameValidator = isInputEmpty(name)
            const mobileNumberValidator = validateMobileNumber(mobileNumber)

            if (!nameValidator.success) setNameError("Please enter name"); else setNameError('')
            if (!mobileNumberValidator.success) setMobileNumberError(mobileNumberValidator.msg); else setMobileNumberError('')

            if (!nameValidator.success || !mobileNumberValidator.success) return

            const payload = {
                pollId: pollData?.id,
                opinionId: selectedOption?.id,
                customerDetails: {
                    name: name,
                    mobile: mobileNumber
                },
                additionalDetails: note
            }

            const submiOpinionRes = await submitOpinion(payload)
            console.log(submiOpinionRes);
            if (submiOpinionRes?.data?.data) {
                setShowTradeSuccessfulModal(true)
                // onClose()
                setTimeout(() => {
                    window.location.href = 'https://www.grooks.in/';
                }, 3000);
            }
            else if (submiOpinionRes?.data?.error) {
                Toaster({
                    type: "error",
                    message: submiOpinionRes?.data?.error?.error,
                });
            }
            else {
                Toaster({
                    type: "error",
                    message: 'Something went wrong',
                });
            }

        } catch (err) {
            Toaster({
                type: "error",
                message: 'Something went wrong',
            });
        }
    }

    return (
        <CustomModal
            visible={visible}
            onClose={onClose}
        >
            <ToastContainer />

            {
                !showParticiantsInput ?
                    <div>
                        <p className="title" >{pollData?.topic}</p>

                        <div className="entry_fees_container " >
                            <p className="entry_fees_text" >Reward : </p>
                            <p className="entry_fees_free_text" >{pollData?.reward?.type == REWARDS_TYPE.MONEY && CURRENCY}{pollData?.reward?.amount} {pollData?.reward?.type == REWARDS_TYPE.GROOKS_POINTS ? 'Grooks Points' : 'Grooks Money'}</p>
                        </div>

                        <EventOptionsComponent
                            data={pollData?.options}
                            selectedOption={selectedOption}
                            onSelectOption={onSelectOption}
                        />

                        <LabelInput
                            title={'Additional Note'}
                            onChangeValue={setNote}
                            value={note}
                            type={INPUT_TYPE.TEXT}
                            multiple={true}
                            placeholder='Say Something...'
                            mainContainerStyle={{marginTop: '0.5rem'}}
                        />

                        <CustomButton
                            marginTop={'1rem'}
                            title={'Next'}
                            onClick={onPressNext}
                        />
                    </div>
                    :
                    !showTradeSuccessfulModal ?
                        <div>
                            <h1 className='title' style={{ textAlign: 'center' }} >Participant Details</h1>
                            <LabelInput
                                title={'Name'}
                                onChangeValue={setName}
                                error={nameError}
                                value={name}
                                placeholder={'Subodh Kumar'}
                                type={INPUT_TYPE.TEXT}
                            />
                            <LabelInput
                                title={'Mobile Number'}
                                onChangeValue={setMobileNumber}
                                error={mobileNumberError}
                                value={mobileNumber}
                                placeholder={'0000000000'}
                                type={INPUT_TYPE.TEL}
                                
                            />
                            <CustomButton
                                marginTop={'1rem'}
                                title={'Submit'}
                                onClick={placeTrade}
                            />
                        </div>
                        :
                        <div className='tradeSuccesContainer' >
                            <img src={Images.OPINION_SUCCESS} />
                            <text>Opinion Placed</text>
                            <p>Redirecting you to Grooks Website</p>
                        </div>
            }


        </CustomModal>
    )
}

export default TradeOpinionModal;