export function isInputEmpty(value) {
    if (value === '') {
        return ({ msg: '', success: false })
    }
    else {
        return ({ msg: '', success: true })
    }
}

export function validateMobileNumber(number) {
    var mob = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (number === "") {
        return ({ msg: 'Please enter mobile number', success: false })
    } else if (mob.test(number) === false || number  < 6000000000) {
        return ({ msg: 'Enter valid mobile number', success: false })
    }
    return ({ msg: '', success: true })
}