import './featureComponent.css'

const FeatureComponent = ({title, description, icon, isReverse}) => {
    return (
        <div className='featureComponent' style={{flexDirection: isReverse ? 'row-reverse': 'row'}} >
            <div>
                <p className='featureTitle'>{title}</p>
                <p className='featureDescription' >{description}</p>
            </div>
            <img src={icon} className='featureIcon' />
        </div>
    )
}

export default FeatureComponent;