import React, { useEffect, useState } from "react";
import { useLazyGetPollDetailsQuery } from "../../../redux/api/poll/poll.api";
import { getQueryVariable } from "../../../utility/commonFunction";
import TradeOpinionModal from "../../modal/tradeOpinionModal/tradeOpinionModal";
import EventOptionsComponent from "../../module/eventOptionsComponent/eventOptionsComponent";
import './index.css';

const SurveyQuestionDetail = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [showTradeOpinionModal, setShowTradeOpinionModal] = useState(false)
    const [selectedOption, setSelectedOption] = useState()

    const [getPollDetails, { data: pollRes, isLoading: isPollRessLoading, error: pollResError, isError: isPollError }] = useLazyGetPollDetailsQuery()

    useEffect(() => {
        let questionID = getQueryVariable('questionId')
        getPollDetails(questionID)
    }, [])

    useEffect(() => {
        if (!isPollRessLoading) {
            setTimeout(() => {
                setIsLoading(false)
            }, 200);
        }
    }, [isPollRessLoading])

    function onSelectOption(item) {
        setShowTradeOpinionModal(true)
        setSelectedOption(item)
    }

    return (
        <div className="mainContainer" >
            <div className="mobileView" >
                <div className="contentContainer" >
                    {/* <div className="contentContainer" > */}
                    <h1 className="appName" >Grooks Survey</h1>
                    {
                        isLoading ? <div className="centerLoading" />
                            :
                            <>                    {
                                pollRes?.error ? <p className="apiCallFailMsg" >{pollRes?.error?.message}</p>
                                    :
                                    <>
                                        <img className="questionImage" src={pollRes?.data?.image} />
                                        {/* <div className="questionImage " /> */}
                                        <p className="title" >{pollRes?.data?.topic || ''}</p>

                                        <div className="entry_fees_container " >
                                            <p className="entry_fees_text" >Entry Fees : </p>
                                            <p className="entry_fees_free_text" >Free</p>
                                        </div>

                                        <EventOptionsComponent data={pollRes?.data?.options || []} onSelectOption={onSelectOption} />
                                        <div className="seprator" />
                                        <div>
                                            <h6 className="title" >Trade Overview</h6>
                                            <p className="description" >{pollRes?.data?.description || ''}</p>
                                        </div>

                                        <div className="source_of_truth_container" >
                                            <p className="title" >Source of truth : </p>
                                            <p className="source_of_truth" >{pollRes?.data?.sourceOfTruth}</p>
                                        </div>
                                    </>
                            }
                            </>
                    }


                    {/* </div> */}
                    <TradeOpinionModal
                        visible={showTradeOpinionModal}
                        onClose={() => setShowTradeOpinionModal(false)}
                        selectedOption={selectedOption}
                        pollData={pollRes?.data}
                        onSelectOption={onSelectOption}
                    />
                </div>
            </div>
        </div>
    )
}

export default SurveyQuestionDetail