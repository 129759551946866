import { Link } from 'react-router-dom'
import Images from '../../../utility/imagesPath'
import './index.css'
import Hamburger from 'hamburger-react'
import { useState } from 'react'
import { useGetAppSettingQuery } from '../../../redux/api/setting/appSetting.api'
import { GROOKS_RESPONSIBLE_PLAY, GROOKS_TERMS_AND_CONDITION } from '../../../redux/apiTypes'
import AppDownloadButton from '../../common/buttons/appDownloadButton/appDownloadButton'

const HEADER_ITEMS = [
    // {
    //     title: "How to trade",
    //     url: "#",
    //     img: Images.PLAY_ICON,
    //     onPress: () => { }
    // },
    {
        title: "T&C",
        url: "#",
        onPress: () => {
            openLinkInNewTab(GROOKS_TERMS_AND_CONDITION)
        }
    },
    {
        title: "Responsible Play",
        url: "#",
        onPress: () => {
            window.location.href = GROOKS_RESPONSIBLE_PLAY;
        }
    },
    {
        url: "#",
        img: Images.DOWNLOAD_ICON,
        onPress: (apkLink) => {
            openLinkInNewTab(apkLink)
        }
    },
]

const MOBILE_HEADER_ITEMS = [
    // {
    //     title: "How to trade",
    //     url: "#",
    //     img: Images.PLAY_ICON,
    //     onPress: () => { }
    // },
    {
        title: "T&C",
        url: "#",
        img: Images.TERMS_AND_CONDITION,
        onPress: () => {
            openLinkInNewTab(GROOKS_TERMS_AND_CONDITION)

        }
    },
    {
        title: "Responsible Play",
        url: "#",
        img: Images.RESPONSIBLE_PLAY,
        onPress: () => {
            window.location.href = GROOKS_RESPONSIBLE_PLAY;
        }
    },
    {
        url: "#",
        title: 'Download App',
        img: Images.SMALL_DOWNLOAD_ICON,
        onPress: (apkLink) => {
            openLinkInNewTab(apkLink)
        }
    },
]

function openLinkInNewTab(apkLink) {
    window.open(apkLink, '_blank', 'noopener,noreferrer');

}

const HeaderComponent = () => {

    const [isHamburgerToggled, setIsHamburgerToggled] = useState(false)
    const { data: setting } = useGetAppSettingQuery()

    return (
        <div className='header_mainContainer' >
            <div className='header_container appPaddingHorizontal' >
                <div className='app_logo_container' >
                    <Link to={'./'}  >
                        <img src={Images.APP_LOGO} className='appLogo' alt='app_logo' />
                    </Link>
                </div>
                <div className={'navigationBar_links'} >
                    <ul >
                        {
                            HEADER_ITEMS.map((item, index) => {
                                return (
                                    <li key={index} className='' onClick={() => item?.onPress(setting?.data?.apkLink)}   >
                                        {/* <Link to={item.url} onClick={() => { }} className='flexAlignCenter' > */}
                                        {item?.img && <img src={item?.img} alt={`${item?.title}_img` || 'img'} />}
                                        {item?.title && <p>{item?.title}</p>}
                                        {/* </Link> */}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>

                <div className="navigationBar_leftComponent" >
                    <AppDownloadButton />
                    <Hamburger
                        toggled={isHamburgerToggled}
                        toggle={setIsHamburgerToggled}
                        color='#1A385A'
                        easing='ease-in-out'
                        rounded
                        size={25}
                    />
                </div>
            </div>
            {
                isHamburgerToggled &&
                <div className={"navigationBar_linkMobile"} >
                    <ul >
                        {
                            MOBILE_HEADER_ITEMS.map((item, index) => {
                                return (
                                    <li key={index} className='' onClick={() => item?.onPress(setting?.data?.apkLink)}   >
                                        {/* <Link to={item.url} onClick={() => { }} className='flexAlignCenter' > */}
                                        {item?.img && <img src={item?.img} alt={`${item?.title}_img` || 'img'} />}
                                        {item?.title && <p>{item?.title}</p>}
                                        {/* </Link> */}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            }
        </div>
    )
}

export default HeaderComponent