import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import Home from './component/pages/home';
import SurveyQuestionDetail from './component/pages/surveyQuestionDetail';
import { Provider } from 'react-redux';
import reduxStore from './redux/store';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "*",
    element: <Home />,
  },
  {
    path: "/surveyQuestionDetail",
    element: <SurveyQuestionDetail />,
  },
]);

function App() {
  return (
    <div className="App">
      <Provider store={reduxStore}  >
      <div className='contentBox' >
        <RouterProvider router={router} />
      </div>
      </Provider>
    </div>
  );
}

export default App;
